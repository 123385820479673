/* eslint-disable @typescript-eslint/no-explicit-any */
import { addRxPlugin, createRxDatabase, deepEqual, ModifyFunction, RxCollection, RxDatabase, RxDocument } from "rxdb";
import { getRxStorageLoki } from 'rxdb/plugins/storage-lokijs';

import {
  getFetchWithCouchDBAuthorization,
  replicateCouchDB,
  RxCouchDBReplicationState
} from 'rxdb/plugins/replication-couchdb';
import { inject, Injectable } from "@angular/core";
import { CONSTANT, COUCHDB, DB, ORDER } from "src/app/constant";
import { AppState } from "../app/state/app.state";
import { Store } from "@ngrx/store";
import { syncDb } from "../app/state/kiosk/kiosk.selector";
import { RxDBCleanupPlugin } from 'rxdb/plugins/cleanup';
import { RxDBUpdatePlugin } from "rxdb/plugins/update";
import { loadTransactionalData, syncing } from "../app/state/account/account.action";
import { Subscription } from "rxjs";
import { getMaestroTable } from "src/app/state/kiosk/kiosk.action";
import { MessageService } from "primeng/api";
import { KioskSelectors } from "src/app/state/kiosk";
import { Account } from "src/app/interface/maestro-interface";
import { RxDBLeaderElectionPlugin } from "rxdb/plugins/leader-election";
import { RxDBMigrationPlugin } from 'rxdb/plugins/migration-schema';
import LokiIncrementalIndexedDBAdapter from 'lokijs/src/incremental-indexeddb-adapter';
import { TelemetryService } from "src/app/services/telemetry.service";

@Injectable({
  providedIn: 'root'
})
export class RxdbSyncService {
  store = inject(Store<AppState>);
  msg = inject(MessageService);
  telemetry = inject(TelemetryService);

  couchDb = window.location.protocol === 'https:'
    ? 'https://devcdb.iotzen.app/'
    : 'http://127.0.0.1:5984/'// http://10.10.10.15/ ;// http://10.10.10.15/

  database?: RxDatabase;
  nCollection = 'assetinfo';
  storeCollection = 'stores';
  rState: RxCouchDBReplicationState<any>;
  storeState: RxCouchDBReplicationState<any>;
  syncFailedOrders = new Array<string>();
  syncDbSub: Subscription;
  rsyncTimer = undefined;
  isSwitchTable: boolean;
  isSwitchTable$ = this.store.select(KioskSelectors.getIsSwitchTable).subscribe((res) => this.isSwitchTable = res);

  constructor() {
    addRxPlugin(RxDBCleanupPlugin);
    addRxPlugin(RxDBUpdatePlugin);
    addRxPlugin(RxDBLeaderElectionPlugin);
    addRxPlugin(RxDBMigrationPlugin);
    this.visibilityHandler();
  }

  async createRxDB(dbName = 'synctest') {
    const now = performance.now();
    if (!this.database) {
      // if (isDevMode()) {
      //   await import('rxdb/plugins/dev-mode').then(
      //     module => addRxPlugin(module.RxDBDevModePlugin)
      //   );
      // }

      const db = await createRxDatabase({
        name: dbName,
        storage: getRxStorageLoki({
          adapter: new LokiIncrementalIndexedDBAdapter(),
        }),
        cleanupPolicy: {
          minimumDeletedTime: 1000 * 60 * 60 * 2,
          minimumCollectionAge: 1000 * 60, // 60 seconds
          runEach: 1000 * 60 * 5, // 5 minutes
          awaitReplicationsInSync: true,
          waitForLeadership: true,
        }
      });
      await db.addCollections({
        assetinfo: {
          schema: {
            title: this.nCollection,
            version: 1,
            primaryKey: '_id',
            type: 'object',
            properties: {
              _id: {
                type: 'string',
                "maxLength": 100
              },
              data: { type: ['object', 'array'] },
              collectionName: { type: 'string' },
              updatedAt: { type: 'number' }
            },
            required: ['data', 'collectionName'],
          },
          autoMigrate: true,
          migrationStrategies: {
            // 1 means, this transforms data from version 0 to version 1
            1: function (oldDoc) {
              oldDoc.updatedAt = new Date(oldDoc.updateAt ?? oldDoc.updatedAt).getTime(); // string to unix
              return oldDoc;
            }
          },
          conflictHandler: (i, context) => {
            const cName = i.newDocumentState?.['collectionName'];
            if (deepEqual(i.newDocumentState, i.realMasterState)) {
              return Promise.resolve({
                isEqual: true
              });
            }

            const setLatestDoc = () => {
              if (i.newDocumentState?.['updatedAt'] > i.realMasterState?.['updatedAt']) {
                doc = i.newDocumentState;
              } else {
                doc = i.realMasterState;
              }

              if (i?.assumedMasterState && i.assumedMasterState?.['updatedAt'] > doc?.['updatedAt']) {
                doc = i.assumedMasterState;
                console.log('conflictHandler -> assumedMasterState', context, cName);
              }
            }

            let doc: any;
            if (cName === DB.POS_ORDER) {

              if (i.newDocumentState?.['data']?.['orderStatus'] === ORDER.PLACED || i.newDocumentState?.['data']?.['orderStatus'] === ORDER.DISCARDED) {
                doc = i.newDocumentState;
              }

              if (i.realMasterState?.['data']?.['orderStatus'] === ORDER.PLACED || i.realMasterState?.['data']?.['orderStatus'] === ORDER.DISCARDED) {
                doc = i.realMasterState;
              }

              if (i?.assumedMasterState?.['data']?.['orderStatus'] === ORDER.PLACED || i?.assumedMasterState?.['data']?.['orderStatus'] === ORDER.DISCARDED) {
                doc = i.assumedMasterState;
              }

              if (!doc) {
                setLatestDoc();
              }
            } else {
              setLatestDoc()
            }


            return Promise.resolve({
              isEqual: false,
              documentData: doc
            });
          }
        }
      });
      this.database = db;

      // check if migration is needed
      this.database['assetinfo'].migrationNeeded().then(async needed => {
        console.log('migrationNeeded', needed);
        if (needed) {
          await this.database['assetinfo'].startMigration(200);
          const migrationState = this.database['assetinfo'].getMigrationState();
          migrationState.$.subscribe(value => console.dir(value),)
        }
      }).catch(console.error);


      db.$.subscribe(async changeEvent => {
        const doc = changeEvent?.documentData;
        const pDoc = changeEvent?.previousDocumentData;

        if (pDoc?.data?.orderStatus === ORDER.PLACED && doc?.data?.orderStatus === ORDER.CREATED) {
          this.syncFailedOrders.push(doc?._id);
          window.alert(`SYNC FAILED: ${doc?._id}, order status: ${pDoc?.data?.orderStatus}`);
        } else {
          console.log(`********* SYNCED ${doc?._id} *******`, 'Failed:', this.syncFailedOrders);
        }
      });
      console.log('Init DB, Loaded the schema', performance.now() - now);
    } else {
      console.log('Database already initiated');
    }

    this.setUpReplication(dbName);
    this.updateTelemetry();
    return this.database;
  };

  async checkSyncDBConnectivity(url: string) {
    try {
      const controller = new AbortController();
      const timeoutId = setTimeout(() => controller.abort(), 1000 * 10);
      const resp = await fetch(url, {
        method: 'GET', signal: controller.signal, redirect: 'follow',
      });
      clearTimeout(timeoutId);

      return resp;
    } catch (e) {
      if (e instanceof DOMException) {
        return new Error('Sync url unreachable');
      }
      return e;
    }
  }

  async updateTelemetry() {
    try {
      const telemetry = await Promise.all([
        this.telemetry.getNetworkInfo(),
        this.telemetry.getStorageInfo(),
        this.telemetry.getUserAgentData()
      ]);
      const accountId = localStorage.getItem('accountId');
      const userId = localStorage.getItem('userId');

      if (accountId && userId) {
        this.insert({
          _id: `tele_${accountId}_${userId}`,
          collectionName: 'telemetry',
          updatedAt: Date.now(),
          data: {
            networkInfo: telemetry?.[0],
            storageInfo: telemetry?.[1],
            userAgentInfo: telemetry?.[2]
          },
        }).then(console.log).catch(console.error);
      }
    } catch (error) {
      console.error(error);
    }
  }

  async createStoreDb(dbName: string, sync: Sync) {
    if (!this.database['stores']) {
      await this.database.addCollections({
        stores: {
          schema: {
            title: this.storeCollection,
            description: "keep track of all the stores logged in",
            version: 0,
            primaryKey: "_id",
            type: "object",
            properties: {
              _id: {
                type: 'string',
                maxLength: 100
              },
              status: { type: 'string' },
              name: { type: 'string' }
            },
            required: ['name']
          }
        }
      })
    }

    const s = `${localStorage.getItem('accountId')}_${localStorage.getItem('userId')}_${JSON.parse(localStorage.getItem(CONSTANT.KIOSK.STORE_lOCATION))?.['_id']}`;
    await this.database[this.storeCollection].upsert({
      _id: `store_${s}`,
      status: 'created',
      name: dbName
    })
      .then(r => console.log('insert doc', r))
      .catch(console.error);

    const couchdbUrl = `${sync.url}${this.storeCollection}/`;

    if (this.storeState && couchdbUrl !== this.storeState.url) {
      await this.storeState.cancel();
      this.storeState = undefined;
    } else {
      if (this.storeState) {
        return;
      }
    }

    this.storeState = replicateCouchDB({
      collection: this.database[this.storeCollection],
      url: couchdbUrl,
      live: true,
      fetch: getFetchWithCouchDBAuthorization(sync.userid ?? COUCHDB.USER, sync.password ?? COUCHDB.PASS),
      autoStart: true,
      pull: {
        batchSize: 100,
      },
      push: {
        batchSize: 100,
      },
      replicationIdentifier: dbName
    });

    this.storeState.error$.subscribe(error => {
      if (error.message.includes("Database does not exist.")) {
        const s = couchdbUrl.split('/');
        const creatUrl = `${window.location.protocol}//${s[s.length - 3]}/${s[s.length - 2]}`;
        console.log('Creating new database:', creatUrl);
        fetch(creatUrl, {
          method: 'PUT',
          headers: { 'Authorization': `Basic ${btoa(`${sync.userid ?? COUCHDB.USER}:${sync.password ?? COUCHDB.PASS}`)}` }
        }).then(x => {
          console.log('Database created', x.status, x.statusText);
        }).catch(console.error);
      }
    });
  }

  async bulkTableInsert(tables: Partial<any>[]) {
    const t = new Array<Promise<any>>(tables.length);
    tables.forEach((table) => t.push(this.getCollection(this.nCollection).insert(table)));
    // return this.getCollection(this.nCollection).bulkInsert(tables)
    return Promise.all(t);
  }

  async createOrder(order: any) {
    order['updatedAt'] = Date.now();
    console.log('Data inserted to db', order);
    return this.getCollection(this.nCollection).insert(order)
  }

  async getOrderById(orderId: string) {
    return this.getCollection(this.nCollection).findOne({
      selector: {
        collectionName: DB.POS_ORDER,
        _id: orderId
      }
    }).exec();
  }

  async insertData(data: any) {
    const accountId = localStorage.getItem('accountId');
    const userId = localStorage.getItem('userId');
    if (!userId) {
      setTimeout(() => this.insertData(data), 1000 * 5);
    } else {
      const insert = {
        ...data,
        _id: `${data.collectionName}_${accountId}_${userId}`,
        updatedAt: Date.now()
      }
      console.log('Data inserted to db', insert);
      return this.getCollection(this.nCollection).upsert(insert);
    }
  }

  async insert(data: unknown) {
    return this.getCollection(this.nCollection).upsert(data);
  }

  async modifyOrder(modifyFunction: ModifyFunction<unknown>, orderId: string) {
    return new Promise((resolve, reject) => {
      this.getCollection(this.nCollection).findOne({
        selector: {
          collectionName: DB.POS_ORDER,
          _id: orderId
        }
      }).exec().then((doc: RxDocument) => {
        const latest = doc?.getLatest();

        if (doc?.isInstanceOfRxDocument && latest) {
          doc.modify(modifyFunction)
            .then(r => resolve(r))
            .catch(er => reject(er));
        } else {
          reject(new Error(`Doc not found for _id: ${orderId}, ${doc?.toString()}`));
        }
      })
    });
  }

  async modifyTables(modifyFunction: ModifyFunction<unknown>, tableId: string) {
    return new Promise((resolve, reject) => {
      this.getCollection(this.nCollection).findOne({
        selector: {
          collectionName: DB.POS_TABLE,
          _id: tableId
        }
      }).exec().then((doc: RxDocument) => {
        if (doc.isInstanceOfRxDocument && doc.getLatest()) {
          // modifyFunction(doc._data)?.['then']((update: Partial<any>) => {
          //   doc.incrementalPatch(update).then(resolve).catch(reject);
          // });

          doc.getLatest().modify(modifyFunction).then(r => {
            resolve(r)
          }).catch(er => {
            console.error(er)
            reject(er);
          });
        } else {
          reject(new Error(`Doc not found for tables} ${doc?.toString()}, tableId: ${tableId}`));
        }
      })
    });
  }

  async getOrdersBetweenTime(_start: number, end: number, skip?: number, limit?: number) {
    const store = JSON.parse(localStorage.getItem(CONSTANT.KIOSK.STORE_lOCATION));
    return this.getCollection(this.nCollection).find({
      selector: {
        "data.orderStatus": "placed",
        "data.payment.status": "paid",
        "data.storeId": store?.['_id'],
        // "data.orderDetails.orderDate": {
        //   $gte: _start
        // },
        "data.placedAt": {
          $gte: _start,
          $lte: end
        }
      },
      skip: skip,
      limit: limit
    }).exec();
  }

  async bulkOrderInsert(orders: Partial<any>[]) {
    return this.getCollection(this.nCollection).bulkInsert(orders)
  }

  async clean() {
    try {
      await Promise.all([
        this.rState.cancel(),
        this.storeState.cancel()
      ]);
      clearInterval(this.rsyncTimer);
      this.syncDbSub?.unsubscribe();
      this.isSwitchTable$?.unsubscribe();
      this.rState = undefined;
      this.storeState = undefined;
      // this.database = undefined;
    } catch (e) {
      console.error(e);
    }
  }

  setUpReplication(dbName: string) {
    this.syncDbSub = this.store.select(syncDb).subscribe(async sync => {
        const r = window.location.host.includes('localhost') ? this.couchDb : sync?.url;

        if (r && sync?.userid && sync?.password) {
          this.store.dispatch(syncing({ isSyncing: true, msg: `Checking For Sync availability with: ${r}`, url: r }));

          const resp = await this.checkSyncDBConnectivity(r);

          if (resp?.ok) {
            const syncUrl = `${r}${dbName}/`;

            if (this.rState && syncUrl !== this.rState.url) {
              await this.rState.cancel();
              this.rState = undefined;
            } else {
              if (this.rState) {
                return;
              }
            }

            const conf = {
              url: syncUrl,
              password: sync.password,
              userid: sync.userid
            };
            this.replicationWithCouchDB(conf, this.database[this.nCollection]);

            this.createStoreDb(dbName, { ...sync, url: r }).then(console.log).catch(console.error);

            this.rState.awaitInSync().then(_r => {
              this.store.dispatch(syncing({
                isSyncing: false,
                msg: 'Done',
                url: r
              }))
            }).catch(er => {
              this.store.dispatch(syncing({ isSyncing: true, msg: 'Error', error: er, url: r }));
            });
          } else {
            this.store.dispatch(syncing({ isSyncing: false, msg: 'Error', error: resp }));
            this.msg.add({
              key: 'notification',
              severity: 'error',
              sticky: true,
              summary: 'Sync Error',
              detail: `${r}, sync url not able to connect, ${resp?.statusText ?? ''}`,
            })
          }
        }
      });
  }

  getCollection(name: string) {
    return this.database?.[name];
  }

  getDeletedDoc() {
    const c = this.getCollection(this.nCollection);
    const query = {
      selector: {
        collectionName: DB.POS_ORDER,
        _deleted: true
      }
    }
    return c.find(query).$;
  }

  getDbData(cName: string) {
    const accountId = localStorage.getItem('accountId');
    const userId = localStorage.getItem('userId');
    const c = this.getCollection(this.nCollection);
    const query = {
      selector: {
        collectionName: cName,
        _id: `${cName}_${accountId}_${userId}`
      }
    }
    return c.find(query).$;
  }

  getAllShift(_start: number, _end: number) {
    const userId = localStorage.getItem('userId');
    if (userId) {
      return this.getCollection(this.nCollection).find({
        selector: {
          collectionName: DB.POS_SHIFT,
          "data.userId": userId,
          "data.startDate": {
            $gte: new Date(_start).toISOString(),
          },
          // "data.endDate": {
          //   $lte: new Date(_end).toISOString()
          // }
        },
        sort: [{ updatedAt: 'desc' }]
      }).$;
    } else {
      throw new Error(`${userId} doesn't exist`);
    }
  }

  isShiftInProgress() {
    let selector = {
      "collectionName": DB.POS_SHIFT,
      'data.status': true
    }
    // const shiftId = undefined; //localStorage.getItem('shiftId');

    // if (shiftId) {
    //   selector['_id'] = shiftId
    // } else {
    selector = {
      ...selector, ...{
        'data.userId': localStorage.getItem('userId'),
        'data.accountId': localStorage.getItem('accountId'),
        "data.storeId": localStorage.getItem('storeId'),
      }
    }
    // }

    return this.getCollection(this.nCollection).findOne({
      selector
    }).$;
  }

  createShift(): Promise<RxDocument> {
    const userId = localStorage.getItem('userId');
    const id = `shift_${userId}_${Date.now().toString(16)}`;
    const store = JSON.parse(localStorage.getItem(CONSTANT.KIOSK.STORE_lOCATION));
    return this.getCollection(this.nCollection).insert({
      data: {
        "_id": id,
        "inProgress": true,
        "accountId": localStorage.getItem('accountId'),
        "userId": userId,
        "storeId": store?.['_id'],
        "startDate": new Date().toISOString(),
        "created": new Date().toISOString(),
        "status": true,
      },
      collectionName: DB.POS_SHIFT,
      _id: id,
      updatedAt: Date.now()
    });
  }

  modifyShift(modifyFunc: ModifyFunction<unknown>) {
    const store = JSON.parse(localStorage.getItem(CONSTANT.KIOSK.STORE_lOCATION));
    return new Promise<RxDocument>((resolve, reject) => {
      const userId = localStorage.getItem('userId');
      this.getCollection(this.nCollection).findOne({
        selector: {
          collectionName: DB.POS_SHIFT,
          'data.userId': userId,
          'data.accountId': localStorage.getItem('accountId'),
          "data.storeId": store?.['_id'],
          'data.status': true,
        }
      }).exec().then((doc: RxDocument) => {
        const latest = doc.getLatest();
        if (doc.isInstanceOfRxDocument && latest) {
          latest.modify(modifyFunc)
            .then(r => resolve(r))
            .catch(er => reject(er));
        } else {
          reject(new Error(`Doc not found for shift}`));
        }
      })
    });
  }

  getTables(areaId?: string, status?: string, _id?: string, asPromise = false) {
    const query = {
      collectionName: DB.POS_TABLE
    }

    const store = localStorage.getItem(CONSTANT.KIOSK.STORE_lOCATION);
    if (store) {
      try {
        const storeId = JSON.parse(store)?.['_id'];
        if (storeId) {
          query['data.storeId'] = storeId;
        }
      } catch (e) {
        console.error(e);
      }
    }

    if (areaId) {
      query["data.areaId"] = areaId
    }

    if (status) {
      query["data.aProperties.status"] = status;
    }

    if (_id) {
      query['_id'] = _id;
    }

    const rxQuery = this.getCollection(this.nCollection)
      .find({
        selector: query
      });

    if (asPromise) {
      return rxQuery.exec();
    } else {
      return rxQuery.$;
    }
  }

  replicationWithCouchDB(sync: Sync, collection: RxCollection) {
    console.log('Replication With CouchDB Init');
    const couchdbUrl = sync.url;
    let docCount = 0;
    this.rState = replicateCouchDB({
      collection: collection,
      url: couchdbUrl,
      live: true,
      fetch: getFetchWithCouchDBAuthorization(sync.userid ?? COUCHDB.USER, sync.password ?? COUCHDB.PASS),
      autoStart: true,
      pull: {
        batchSize: 200,
      },
      push: {
        batchSize: 200,
      },
      replicationIdentifier: couchdbUrl
    });

    this.getCollection(this.nCollection).remove$.subscribe((_v) => {
      console.log('Document removed locally:', _v);
    });

    this.rState.received$.subscribe(doc => {
      console.log('emits each document that was received from the remote', doc);
      if (doc?._deleted) {
        console.log('************* getDeletedDoc:', doc);
      }

      if (doc['collectionName'] === DB.POS_TABLE) {
        const cf = localStorage.getItem('currentFloor');
        const areaId = cf !== undefined ? JSON.parse(cf) : doc?.['data']?.['areaData']?.['_id'];
        this.store.dispatch(getMaestroTable({areaId,status: this.isSwitchTable ? 'available' : undefined,remote: true}));
      }
      this.store.dispatch(syncing({
        msg: `Sync - ${docCount++} - Received From The Remote: ${doc['_id']}, ${doc['updatedAt']}`,
        error: undefined
      }));
    });

    this.rState.sent$.subscribe(doc => {
      console.log('emits each document that was send to the remote', doc);
      if (doc['collectionName'] === DB.POS_ORDER) {
        console.log('orderDetails', doc?.['data']?.['orderDetails']?.['items']?.length);
      }

      this.store.dispatch(syncing({
        msg: `Sync ${docCount++} - Send To The Remote: ${doc['_id']} ${doc['updatedAt']}`,
        error: undefined
      }));
    });

    this.rState.error$.subscribe(error => {
      if (error?.parameters?.errors?.[0]?.code?.toString() == '426') {
        // client is outdated -> enforce a page reload
        location.reload();
      }

      const errorString = `${error.name} - ${error?.parameters?.errors?.map(e => `${e.name} - ${e.message}`)}`
      this.store.dispatch(syncing({ msg: errorString, error: error }));
      if (error.message.includes("Database does not exist.")) {
        this.store.dispatch(syncing({
          isSyncing: true,
          msg: `Remote DB does not exist. Creating Remote`,
          error: undefined
        }));
        this.store.dispatch(loadTransactionalData({ server: true }));

        const s = couchdbUrl.split('/');
        const creatUrl = `${window.location.protocol}//${s[s.length - 3]}/${s[s.length - 2]}`;

        console.log('Creating new database:', creatUrl);
        fetch(creatUrl, {
          method: 'PUT',
          headers: { 'Authorization': `Basic ${btoa(`${sync.userid ?? COUCHDB.USER}:${sync.password ?? COUCHDB.PASS}`)}` }
        }).then(x => {
          console.log('Database created', x.status, x.statusText)
        }).catch(console.error);
      }
    });

    this.rState.canceled$.subscribe(bool => console.log('emits true when the replication was canceled, false when not.', bool));


    this.rState.active$.subscribe(bool => {
      console.log('emits true when a replication cycle is running, false when not.', bool);
      // this.store.dispatch(syncing({ msg: `Sync - Replication Is Running ${bool}` }));
    });

    return this.rState;
  }

  visibilityHandler = () => {
    document.addEventListener("visibilitychange", _evt => {
      if (this.rState?.isStopped()) {
        this.msg.add({ severity: 'error', summary: 'Sync Stopped', key: 'notification' });
        this.rState?.start().then(console.log).catch(console.error);
      }

      if (!document.hidden) {
        if (this.rState) {
          const url = this.rState.url.split(this.rState?.metaInstance?.databaseName)[0];
          this.checkSyncDBConnectivity(url).then(resp => {
            if (resp?.ok) {
              this.store.dispatch(syncing({ isSyncing: true, msg: 'syncing' }));
              this.rState?.awaitInSync().then(_ => {
                console.log("****** document visibilitychange awaitInSync *******", _);
                this.store.dispatch(syncing({ isSyncing: false, msg: 'Done' }));
              }).catch(er => this.msg.add({
                severity: 'error',
                summary: 'Sync Stopped',
                key: 'notification',
                detail: `Error: ${er.toString()}`
              }));
              // this.msg.add({
              //   key: 'notification',
              //   severity: 'info',
              //   summary: 'Syncing',
              //   detail: 'Please stand by....'
              // })
            } else {
              this.msg.add({
                key: 'notification',
                severity: 'error',
                sticky: true,
                summary: 'Sync Error',
                detail: `Re-Sync Failed, Not reachable, ${resp?.statusText ?? ''}`,
              })
            }
          });
        } else {
          const acc: Account = JSON.parse(localStorage.getItem('account'));
          const storeId = localStorage.getItem('storeId');
          if (acc?._id && storeId) {
            const dbName = `db_${acc._id}_${storeId}`;
            this.setUpReplication(dbName);
          }
        }
      }
    });
  }

  getOperators() {
    return this.getCollection(this.nCollection).findOne({
      selector: {
        collectionName: DB.POS_OPERATORS,
        'data.accountId': localStorage.getItem('accountId')
      }
    }).exec();
  }

  getDbDateById(id: string) {
    return this.getCollection(this.nCollection).findOne({
      selector: {
        _id: id
      }
    }).exec()
  }
}

interface Sync {
  url: string
  userid: string
  password: string
}


export interface DbDoc {
  collectionName: string,
  data: any,
  updatedAt: number,
  _id: string
}
